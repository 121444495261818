exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-coverage-tsx": () => import("./../../../src/pages/coverage.tsx" /* webpackChunkName: "component---src-pages-coverage-tsx" */),
  "component---src-pages-dmca-copyright-policy-tsx": () => import("./../../../src/pages/DMCACopyrightPolicy.tsx" /* webpackChunkName: "component---src-pages-dmca-copyright-policy-tsx" */),
  "component---src-pages-existing-customers-tsx": () => import("./../../../src/pages/existing-customers.tsx" /* webpackChunkName: "component---src-pages-existing-customers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledgebase-tsx": () => import("./../../../src/pages/knowledgebase.tsx" /* webpackChunkName: "component---src-pages-knowledgebase-tsx" */),
  "component---src-pages-new-customer-tsx": () => import("./../../../src/pages/new-customer.tsx" /* webpackChunkName: "component---src-pages-new-customer-tsx" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sales-faq-tsx": () => import("./../../../src/pages/salesFAQ.tsx" /* webpackChunkName: "component---src-pages-sales-faq-tsx" */),
  "component---src-pages-service-agreement-tsx": () => import("./../../../src/pages/serviceAgreement.tsx" /* webpackChunkName: "component---src-pages-service-agreement-tsx" */),
  "component---src-templates-legal-docs-tsx": () => import("./../../../src/templates/legalDocs.tsx" /* webpackChunkName: "component---src-templates-legal-docs-tsx" */)
}

